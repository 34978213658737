import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Chip,
  Grow,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Breadcrumbs,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  ExitToApp,
  AccountCircle,
} from '@material-ui/icons';

import { menuData } from './initData';

const ITEM_HEIGHT = 48;
const DRAWER_WIDTH = 200;

const accountMap = ['daisy', 'CHENG', '嘉萱', 'Q婷', '懿珊', '老闆', 'sony', 'lyy1015'];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#333',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 16,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: '#fafafa',
  },
  linked: {
    backgroundColor: '#ddd',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const initPageTitle = {
  group: false,
  page: false,
};

export default function PageMenu(props) {
  const { meObj, pathname } = props; 
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(true);
  const [groupOpen, setGroupOpen] = useState(menuData.map((val, index) => false));
  const [pageTitles, setPageTitle] = useState(initPageTitle);
  const showCost = accountMap.includes(meObj.name);

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchMenu = (index) => {
    setGroupOpen(groupOpen.map((val, i) => {
      return index === i ? !val : val;
    }));
  }

  useEffect(() => {
    setGroupOpen(menuData.map((val, index) => {
      const childPaths = val.children.map(val => val.path);
      const isGroup = childPaths.includes(pathname);

      if (isGroup) {
        setPageTitle({
          group: val,
          page: val.children.find(page => pathname === page.path),
        });
      }

      if (groupOpen[index]) {
        return true;
      }

      return val.children.some(val => val.path === pathname);
    }));
  }, [pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>   
          <Typography variant="h6" className={classes.title}>
            Healer
          </Typography>

          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Chip
              icon={<AccountCircle />}
              label={meObj.name}
              style={{ cursor: 'pointer'}}
            />
          </IconButton>

          <Popper
            open={menuOpen}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper
                  style={{
                    maxHeight: ITEM_HEIGHT * 3.5,
                    width: '100px',
                    fontSize: '16px',
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={menuOpen} id="long-menu">
                      <MenuItem className="header-menu" onClick={props.signout}>
                        <ExitToApp/> <span className="header-menu-text">登出</span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {menuData.map((val, index) => {
            const key = `menu${index}`;
            const gOpen = groupOpen[index];
            const _switch = () => switchMenu(index);
            const childPaths = val.children.map(val => val.path);
            const iconColor = childPaths.includes(pathname) ? 'primary' : 'action';
            const showIcon = () => val.icon(iconColor);

            return (
              <div key={key}>
                <ListItem
                  onClick={_switch}
                  button
                >
                  <ListItemIcon>
                    {showIcon()}
                  </ListItemIcon>
                  <ListItemText primary={val.title} />
                  {gOpen ? <ExpandLess color={iconColor}/> : <ExpandMore color={iconColor}/>}
                </ListItem>
                <Divider />

                <Collapse in={gOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {val.children.map((child, index) => {
                      const { path } = child;
                      const key2 = `sub${index}${index}`;
                      const linked = pathname.includes(path);

                      if (!showCost && ['/purchase'].includes(path)) {
                        return null
                      }

                      return (
                        <Link key={key2} to={path} className={classes.link}>
                          <ListItem
                            className={clsx(classes.nested, {
                              [classes.linked]: linked,
                            })}
                            button
                          >
                              <ListItemText primary={child.title} /> 
                          </ListItem>
                        </Link>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        
        {pageTitles.group &&
          <div className="page-map">
            <Breadcrumbs separator="›" aria-label="breadcrumb" >
              <Typography color="inherit">
                {pageTitles.group.icon()}
                <span>{pageTitles.group.title}</span>
              </Typography>

              <Typography color="textPrimary">
                {pageTitles.page.title}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        {props.children}
      </main>
    </div>
  );
}
