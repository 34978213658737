import { fromJS, Map } from 'immutable';
import { products as cons } from '../constants';

const INIT_USER = {
  loadProductList: false,
  loadProductListSuc: false,
  loadProductListErr: false,
  dataList: [],
  total: 0,

  loadStockList: false,
  loadStockListSuc: false,
  loadStockListErr: false,
  stockList: [],
  stockTotal: 0,

  loadProductsByIds: false,
  loadProductsByIdsSuc: false,
  loadProductsByIdsErr: false,
  idsList: [],
  idstotal: 0,

  syncProduct: false,
  syncProductSuc: false,
  syncProductErr: false,

  updateQty: false,
  updateQtySuc: false,
  updateQtyErr: false,

  loadStock: false,
  loadStockSuc: false,
  loadStockErr: false,
  stockList: [],
  stockTotal: 0,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    
    case cons.GET_PRODUCT_LIST:
      return state.merge({
        loadProductList: true,
        loadProductListSuc: false,
        loadProductListErr: false,
      })
    case cons.GET_PRODUCT_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadProductList: false,
        loadProductListSuc: true,
        loadProductListErr: false,
        dataList: data,
        total,
      })
    }
      
    case cons.GET_PRODUCT_LIST_ERR:
      return state.merge({
        loadProductList: false,
        loadProductListSuc: false,
        loadProductListErr: true,
      })

    case cons.GET_PRODUCT_STOCK_LIST:
      return state.merge({
        loadStockList: true,
        loadStockListSuc: false,
        loadStockListErr: false,
      })

    case cons.GET_PRODUCT_STOCK_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadStockList: false,
        loadStockListSuc: true,
        loadStockListErr: false,
        stockList: data,
        stockTotal: total,
      })
    }
        
    case cons.GET_PRODUCT_STOCK_LIST_ERR:
      return state.merge({
        loadStockList: false,
        loadStockListSuc: false,
        loadStockListErr: true,
      })

    case cons.GET_PRODUCT_LIST_IDS:
      return state.merge({
        loadProductsByIds: true,
        loadProductsByIdsSuc: false,
        loadProductsByIdsErr: false,
      })
    case cons.GET_PRODUCT_LIST_IDS_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadProductsByIds: false,
        loadProductsByIdsSuc: true,
        loadProductsByIdsErr: false,
        idsList: data,
        idstotal: total,
      })
    }
    case cons.GET_PRODUCT_LIST_IDS_ERR:
      return state.merge({
        loadProductsByIds: false,
        loadProductsByIdsSuc: false,
        loadProductsByIdsErr: true,
      })

    case cons.SYNC_PRODUCT:
      return state.merge({
        syncProduct: true,
        syncProductSuc: false,
        syncProductErr: false,
      })
    case cons.SYNC_PRODUCT_SUC:
      return state.merge({
        syncProduct: false,
        syncProductSuc: true,
        syncProductErr: false,
      })
    case cons.SYNC_PRODUCT_ERR:
      return state.merge({
        syncProduct: false,
        syncProductSuc: false,
        syncProductErr: true,
      })

    case cons.UPDATE_QTY:
      return state.merge({
        updateQty: true,
        updateQtySuc: false,
        updateQtyErr: false,
      })
    case cons.UPDATE_QTY_SUC:
      return state.merge({
        updateQty: false,
        updateQtySuc: true,
        updateQtyErr: false,
      })
    case cons.UPDATE_QTY_ERR:
      return state.merge({
        updateQty: false,
        updateQtySuc: false,
        updateQtyErr: true,
      })

    case cons.GET_PRODUCT_STOSK_LIST:
      return state.merge({
        loadStock: true,
        loadStockSuc: false,
        loadStockErr: false,
      })
    case cons.GET_PRODUCT_STOSK_LIST_SUC: {
      const { data, total } = action.result.data;
      return state.merge({
        loadStock: false,
        loadStockSuc: true,
        loadStockErr: false,
        stockList: data,
        stockTotal: total,
      })
    }
    case cons.GET_PRODUCT_STOSK_LIST_ERR:
      return state.merge({
        loadStock: false,
        loadStockSuc: false,
        loadStockErr: true,
      })

    case cons.CLEAR_STOCK:
      return state.merge({
        loadStock: false,
        loadStockSuc: false,
        loadStockErr: false,
        stockList: [],
        stockTotal: 0,
      })

    default:
      return state;
  }
}
