export default {
  GET_LIST: 'returns/GET_LIST',
  GET_LIST_SUC: 'returns/GET_LIST_SUC',
  GET_LIST_ERR: 'returns/GET_LIST_ERR',

  IMPORT: 'returns/IMPORT',
  IMPORT_SUC: 'returns/IMPORT_SUC',
  IMPORT_ERR: 'returns/IMPORT_ERR',

  CREATE: 'returns/CREATE',
  CREATE_SUC: 'returns/CREATE_SUC',
  CREATE_ERR: 'returns/CREATE_ERR',

  CHECK: 'returns/CHECK',
  CHECK_SUC: 'returns/CHECK_SUC',
  CHECK_ERR: 'returns/CHECK_ERR',

  GET_RETURN: 'returns/GET_RETURN',
  GET_RETURN_SUC: 'returns/GET_RETURN_SUC',
  GET_RETURN_ERR: 'returns/GET_RETURN_ERR',

  UPDATE: 'returns/UPDATE',
  UPDATE_SUC: 'returns/UPDATE_SUC',
  UPDATE_ERR: 'returns/UPDATE_ERR',

  DELETE: 'returns/DELETE',
  DELETE_SUC: 'returns/DELETE_SUC',
  DELETE_ERR: 'returns/DELETE_ERR',
}
