export default {
  GET_LIST: 'accpunts/GET_LIST',
  GET_LIST_SUC: 'accpunts/GET_LIST_SUC',
  GET_LIST_ERR: 'accpunts/GET_LIST_ERR',

  CREATE: 'accpunts/CREATE',
  CREATE_SUC: 'accpunts/CREATE_SUC',
  CREATE_ERR: 'accpunts/CREATE_ERR',

  GET_WAREHOUSE: 'accounts/GET_WAREHOUSE',
  GET_WAREHOUSE_SUC: 'accounts/GET_WAREHOUSE_SUC',
  GET_WAREHOUSE_ERR: 'accounts/GET_WAREHOUSE_ERR',

  UPDATE: 'accounts/UPDATE',
  UPDATE_SUC: 'accounts/UPDATE_SUC',
  UPDATE_ERR: 'accounts/UPDATE_ERR',

  DELETE: 'accounts/DELETE',
  DELETE_SUC: 'accounts/DELETE_SUC',
  DELETE_ERR: 'accounts/DELETE_ERR',
}
