import axios from 'axios';
import config from "../config.js";

class AxiosInstance {
  constructor() {
    
    const { PREXY_ACCOUNT_TOKEN, API } = config;
    const TOKEN = localStorage.getItem(PREXY_ACCOUNT_TOKEN);

    this.token = TOKEN;
    this.instance = axios.create({
      baseURL: API,
      withCredentials: true,
      headers: {
        'Authorization': TOKEN,
        'platform': 'H5',
        'Access-Control-Allow-Origin': '*',
      },
    });
    this.init();
    return this.instance;
  }

  init() {
      this.instance.interceptors.response.use(this.successResponse, this.errorResponse);
  }

  successResponse = (response) => {
    const { data } = response;

    // 登入成功更新token
    if (data.token) {
      this.instance.defaults.headers.Authorization = data.token;
    }

    return response;
  };

  errorResponse = (error) => {
    if (!error.__CANCEL__) {
      let errMsg = error.message;
      
      if (error.response) {
        errMsg = `${error.response.status} ${error.response.data.error}`;
      
      } else if (error.request) {
        errMsg = 'server error!';
      }
    }

    return Promise.reject(error);
  };
}

export default new AxiosInstance();
