import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import AxiosInstance from '../util/axios';
import clientMiddleware from './middleware/clientMiddleware';
import * as reducer  from './reducer';
import thunk from 'redux-thunk';

const middleware = [clientMiddleware(AxiosInstance), thunk];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(combineReducers(reducer), composeEnhancer(applyMiddleware(...middleware)))

export default store;
