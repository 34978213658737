import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import Routes from './routes';

import './index.css';

import * as serviceWorker from './serviceWorker';

const appRoot = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  appRoot,
);
serviceWorker.unregister();
