import { fromJS, Map } from 'immutable';
import { products as cons } from '../constants';

const INIT_USER = {
  loadStockListList: false,
  loadStockListListSuc: false,
  loadStockListListErr: false,
  dataList: [],
  total: 0,

  import: false,
  importSuc: false,
  importErr: false,

  addStock: false,
  addStockSuc: false,
  addStockErr: false,

  checkStock: false,
  checkStockSuc: false,
  checkStockErr: false,

  getStock: false,
  getStockSuc: false,
  getStockErr: false,

  updateStock: false,
  updateStockSuc: false,
  updateStockErr: false,

  deleteStock: false,
  deleteStockSuc: false,
  deleteStockErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){

    case cons.GET_LIST: {
      return state.merge({
        loadStockList: true,
        loadStockListSuc: false,
        loadStockListErr: false,
      })
    }
      
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadStockList: false,
        loadStockListSuc: true,
        loadStockListErr: false,
        dataList: data,
        total,
      })
    }
      
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadStockList: false,
        loadStockListSuc: false,
        loadStockListErr: true,
      })
    }

    case cons.IMPORT: {
      return state.merge({
        import: true,
        importSuc: false,
        importErr: false,
      })
    }
    case cons.IMPORT_SUC: {
      return state.merge({
        import: false,
        importSuc: true,
        importErr: false,
      })
    }
    case cons.IMPORT_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        import: false,
        importSuc: false,
        importErr: errMsg,
      })
    }

    case cons.CREATE: {
      return state.merge({
        addStock: true,
        addStockSuc: false,
        addStockErr: false,
      })
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addStock: false,
        addStockSuc: true,
        addStockErr: false,
      })
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addStock: false,
        addStockSuc: false,
        addStockErr: errMsg,
      })
    }

    case cons.CHECK: {
      return state.merge({
        checkStock: true,
        checkStockSuc: false,
        checkStockErr: false,
      })
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkStock: false,
        checkStockSuc: true,
        checkStockErr: false,
      })
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkStock: false,
        checkStockSuc: false,
        checkStockErr: errMsg,
      })
    }

    case cons.GET_STOCK: {
      return state.merge({
        getStock: true,
        getStockSuc: false,
        getStockErr: false,
      })
    }
    case cons.GET_STOCK_SUC: {
      return state.merge({
        getStock: false,
        getStockSuc: true,
        getStockErr: false,
      })
    }
    case cons.GET_STOCK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        getStock: false,
        getStockSuc: false,
        getStockErr: errMsg,
      })
    }

    case cons.UPDATE: {
      return state.merge({
        updateStock: true,
        updateStockSuc: false,
        updateStockErr: false,
      })
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateStock: false,
        updateStockSuc: true,
        updateStockErr: false,
      })
    }
    case cons.UPDATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateStock: false,
        updateStockSuc: false,
        updateStockErr: errMsg,
      })
    }

    case cons.DELETE: {
      return state.merge({
        deleteStock: true,
        deleteStockSuc: false,
        deleteStockErr: false,
      })
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteStock: false,
        deleteStockSuc: true,
        deleteStockErr: false,
      })
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deleteStock: false,
        deleteStockSuc: false,
        deleteStockErr: errMsg,
      })
    }

    default:
      return state;
  }
}
