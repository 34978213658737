import React, { useState, useEffect } from 'react';
import { Router } from 'react-router';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Util from './util';

import history from './util/history';
import App from './containers/App';

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;
    state = { Component: AsyncComponent.Component };

    componentDidMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component;
          this.setState({ Component });
        })
      }
    }
    
    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  };
}

// is login pages
const RoutesIsLogin = [{
  path: '/material',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Material" */ './containers/Material').then(moudle => withRouter(moudle.default))
  ),
}, {
  path: '/warehouse',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Warehouse" */ './containers/Warehouse').then(moudle => moudle.default)
  ),
}, {
  path: '/transfer',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Transfer" */ './containers/Transfer').then(moudle => moudle.default)
  ),
}, {
  path: '/manage',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Manage" */ './containers/Manage').then(moudle => moudle.default)
  ),
}, {
  path: '/purchase',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Purchase" */ './containers/Purchase').then(moudle => moudle.default)
  ),
}, {
  path: '/returns',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Returns" */ './containers/Returns').then(moudle => moudle.default)
  ),
}, {
  path: '/receipt',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Receipt" */ './containers/Receipt').then(moudle => moudle.default)
  ),
}, {
  path: '/order',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Order" */ './containers/Order').then(moudle => moudle.default)
  ),
}, {
  path: '/conversion',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Conversion" */ './containers/Conversion').then(moudle => moudle.default)
  ),
}, {
  path: '/return',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Return" */ './containers/Exchange').then(moudle => moudle.default)
  ),
}, {
  path: '/exchange',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Exchange" */ './containers/OrderExchange').then(moudle => moudle.default)
  ),
}, {
  path: '/account',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Account" */ './containers/Account').then(module => module.default)
  ),
}];

// no login pages
const RoutesUnLogin = [{
  path: '/login',
  component: asyncComponent(() =>
    import( /* webpackChunkName: "Login" */ './containers/Login').then(module => module.default)
  ),
}];

// 404
const NoMatch = asyncComponent(() =>
  import( /* webpackChunkName: "Nomatch" */ './containers/404').then(module => module.default)
);

function Routes() {
  const [loginChecker, setChechLogin] = useState(Util.isLogin());
  const routeList = loginChecker ? RoutesIsLogin : RoutesUnLogin;
  const redirectList = loginChecker ? RoutesUnLogin : RoutesIsLogin;
  const firstUrl = routeList[0].path;

  return (
    <Router history={history}>
      <App
        loginChecker={loginChecker}
        setChechLogin={setChechLogin}
      >
        <Switch>
          {routeList.map((val, index) => {
            return (
              <Route key={index} path={val.path} component={val.component} />
            );
          })}

          {redirectList.map((val, index) => {
            return (
              <Redirect key={index} from={val.path} exact to={firstUrl} />
            );
          })}
          
          <Redirect from='/' exact to={firstUrl} />
          <Route component={NoMatch} />
        </Switch>
      </App>
    </Router>
  );
}

export default Routes;
