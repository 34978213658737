import { TramOutlined, TramRounded, TrendingUpOutlined } from '@material-ui/icons';
import { fromJS, Map } from 'immutable';
import { orders as cons } from '../constants';

const INIT_USER = {
  loadOrderList: false,
  loadOrderListSuc: false,
  loadOrderListErr: false,
  dataList: [],
  total: 0,

  loadCheckList: false,
  loadCheckListSuc: false,
  loadCheckListErr: false,
  checkList: [],
  checkTotal: 0,

  loadStayList: false,
  loadStayListSuc: false,
  loadStayListErr: false,
  stayList: [],
  stayTotal: 0,

  loadCancelList: false,
  loadCancelListSuc: false,
  loadCancelListErr: false,
  cancelList: [],
  cancelTotal: 0,

  loadShipment: false,
  loadShipmentSuc: false,
  loadShipmentErr: false,
  shipmentList: [],
  shipmentTotal: 0,

  loadShopList: false,
  loadShopListSuc: false,
  loadShopListErr: false,
  shopList: [],
  shopTotal: 0,

  orderCheck: false,
  orderCheckSuc: false,                
  orderCheckErr: false,

  syncOrder: false,
  syncOrderSuc: false,
  syncOrderErr: false,

  updateOrder: false,
  updateOrderSuc: false,
  updateOrderErr: false,

  checkOrder: false,
  checkOrderSuc: false,
  checkOrderErr: false,

  lackOrder: false,
  lackOrderSuc: false,
  lackOrderErr: false,

  mergeOrder: false,
  mergeOrderSuc: false,
  mergeOrderErr: false,

  generateLabel: false,
  generateLabelSuc: false,
  generateLabelErr: false,

  batchStatus: false,
  batchStatusSuc: false,
  batchStatusErr: false,

  batchDeliveryStatus: false,
  batchDeliveryStatusSuc: false,
  batchDeliveryStatusErr: false,

  setRemark: false,
  setRemarkSuc: false,
  setRemarkErr: false,

  batchSetStay: false,
  batchSetStaySuc: false,
  batchSetStayErr: false,

  batchSetFirst: false,
  batchSetFirstSuc: false,
  batchSetFirstErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type) {
    case cons.GET_ORDER_LIST: {
      return state.merge({
        loadOrderList: true,
        loadOrderListSuc: false,
        loadOrderListErr: false,
      });
    }
    case cons.GET_ORDER_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadOrderList: false,
        loadOrderListSuc: true,
        loadOrderListErr: false,
        dataList: data,
        total,
      });
    }
    case cons.GET_ORDER_LIST_ERR: {
      return state.merge({
        loadOrderList: false,
        loadOrderListSuc: false,
        loadOrderListErr: true,
      });
    }

    case cons.GET_ORDER_CHECK_LIST: {
      return state.merge({
        loadCheckList: true,
        loadCheckListSuc: false,
        loadCheckListErr: false,
        checkList: [],
        checkTotal: 0,
      })
    }
    case cons.GET_ORDER_CHECK_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadCheckList: false,
        loadCheckListSuc: true,
        loadCheckListErr: false,
        checkList: data,
        checkTotal: total,
      })
    }
    case cons.GET_ORDER_CHECK_LIST_ERR: {
      return state.merge({
        loadCheckList: false,
        loadCheckListSuc: false,
        loadCheckListErr: true,
      })
    }

    case cons.GET_ORDER_STAY_LIST: {
      return state.merge({
        loadStayList: true,
        loadStayListSuc: false,
        loadStayListErr: false,
      })
    }
    case cons.GET_ORDER_STAY_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadStayList: false,
        loadStayListSuc: true,
        loadStayListErr: false,
        stayList: data,
        stayTotal: total,
      })
    }
    case cons.GET_ORDER_STAY_LIST_ERR: {
      return state.merge({
        loadStayList: false,
        loadStayListSuc: false,
        loadStayListErr: true,
      })
    }

    case cons.GET_ORDER_CANCEL_LIST: {
      return state.merge({
        loadCancelList: true,
        loadCancelListSuc: false,
        loadCancelListErr: false,
      })
    }
    case cons.GET_ORDER_CANCEL_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadCancelList: false,
        loadCancelListSuc: true,
        loadCancelListErr: false,
        cancelList: data,
        cancelTotal: total,
      })
    }
    case cons.GET_ORDER_CANCEL_LIST_ERR: {
      return state.merge({
        loadCancelList: false,
        loadCancelListSuc: false,
        loadCancelListErr: true,
      })
    }

    case cons.GET_SHIPMENT_LIST: {
      return state.merge({
        loadShipment: true,
        loadShipmentSuc: false,
        loadShipmentErr: false,
        shipmentList: [],
        shipmentTotal: 0,
      })
    }
    case cons.GET_SHIPMENT_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadShipment: false,
        loadShipmentSuc: true,
        loadShipmentErr: false,
        shipmentList: data,
        shipmentTotal: total,
      })
    }
    case cons.GET_SHIPMENT_LIST_ERR: {
      return state.merge({
        loadShipment: false,
        loadShipmentSuc: false,
        loadShipmentErr: true,
      })
    }

    case cons.GET_ORDER_SHOP_LIST: {
      return state.merge({
        loadShopList: true,
        loadShopListSuc: false,
        loadShopListErr: false,
      })
    }
    case cons.GET_ORDER_SHOP_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadShopList: false,
        loadShopListSuc: true,
        loadShopListErr: false,
        shopList: data,
        shopTotal: total,
      })
    }
    case cons.GET_ORDER_SHOP_LIST_ERR: {
      return state.merge({
        loadShopList: false,
        loadShopListSuc: false,
        loadShopListErr: true,
      })
    }

    case cons.ORDER_CHECK: {
      return state.merge({
        orderCheck: true,
        orderCheckSuc: false,                
        orderCheckErr: false,
      })
    }
    case cons.ORDER_CHECK_SUC: {
      return state.merge({
        orderCheck: false,
        orderCheckSuc: true,                
        orderCheckErr: false,
      })
    }
    case cons.ORDER_CHECK_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        orderCheck: false,
        orderCheckSuc: false,                
        orderCheckErr: errMsg,
      })
    }

    case cons.SYNC_ORDER: {
      return state.merge({
        syncOrder: true,
        syncOrderSuc: false,
        syncOrderErr: false,
      });
    }
    case cons.SYNC_ORDER_SUC: {
      return state.merge({
        syncOrder: false,
        syncOrderSuc: true,
        syncOrderErr: false,
      });
    }
    case cons.SYNC_ORDER_ERR: {
      return state.merge({
        syncOrder: false,
        syncOrderSuc: false,
        syncOrderErr: true,
      });
    }

    case cons.UPDATE_ORDER: {
      return state.merge({
        updateOrder: true,
        updateOrderSuc: false,
        updateOrderErr: false,
      });
    }
    case cons.UPDATE_ORDER_SUC: {
      return state.merge({
        updateOrder: false,
        updateOrderSuc: true,
        updateOrderErr: false,
      });
    }
    case cons.UPDATE_ORDER_ERR: {
      return state.merge({
        updateOrder: false,
        updateOrderSuc: false,
        updateOrderErr: true,
      });
    }

    case cons.CHECK_ORDER: {
      return state.merge({
        checkOrder: true,
        checkOrderSuc: false,
        checkOrderErr: false,
      });
    }
    case cons.CHECK_ORDER_SUC: {
      return state.merge({
        checkOrder: false,
        checkOrderSuc: true,
        checkOrderErr: false,
      });
    }
    case cons.CHECK_ORDER_ERR: {
      return state.merge({
        checkOrder: false,
        checkOrderSuc: false,
        checkOrderErr: true,
      });
    }

    case cons.LACK_ORDER: {
      return state.merge({
        lackOrder: true,
        lackOrderSuc: false,
        lackOrderErr: false,
      });
    }
    case cons.LACK_ORDER_SUC: {
      return state.merge({
        lackOrder: false,
        lackOrderSuc: true,
        lackOrderErr: false,
      });
    }
    case cons.LACK_ORDER_ERR: {
      return state.merge({
        lackOrder: false,
        lackOrderSuc: false,
        lackOrderErr: true,
      });
    }

    case cons.MERGE_ORDER: {
      return state.merge({
        mergeOrder: true,
        mergeOrderSuc: false,
        mergeOrderErr: false,
      });
    }
    case cons.MERGE_ORDER_SUC: {
      return state.merge({
        mergeOrder: false,
        mergeOrderSuc: true,
        mergeOrderErr: false,
      });
    }
    case cons.MERGE_ORDER_ERR: {
      return state.merge({
        mergeOrder: false,
        mergeOrderSuc: false,
        mergeOrderErr: true,
      });
    }

    case cons.GENERATE_LABEL: {
      return state.merge({
        generateLabel: true,
        generateLabelSuc: false,
        generateLabelErr: false,
      });
    }
    case cons.GENERATE_LABEL_SUC: {
      return state.merge({
        generateLabel: false,
        generateLabelSuc: true,
        generateLabelErr: false,
      });
    }
    case cons.GENERATE_LABEL_ERR: {
      return state.merge({
        generateLabel: false,
        generateLabelSuc: false,
        generateLabelErr: true,
      });
    }

    case cons.BATCH_STATUS: {
      return state.merge({
        batchStatus: true,
        batchStatusSuc: false,
        batchStatusErr: false,
      })
    }
    case cons.BATCH_STATUS_SUC: {
      return state.merge({
        batchStatus: false,
        batchStatusSuc: true,
        batchStatusErr: false,
      })
    }
    case cons.BATCH_STATUS_ERR: {
      return state.merge({
        batchStatus: false,
        batchStatusSuc: false,
        batchStatusErr: true,
      })
    }

    case cons.BATCH_DELIVERY_STATUS: {
      return state.merge({
        batchDeliveryStatus: true,
        batchDeliveryStatusSuc: false,
        batchDeliveryStatusErr: false,
      })
    }
    case cons.BATCH_DELIVERY_STATUS_SUC: {
      return state.merge({
        batchDeliveryStatus: false,
        batchDeliveryStatusSuc: true,
        batchDeliveryStatusErr: false,
      })
    }
    case cons.BATCH_DELIVERY_STATUS_ERR: {
      return state.merge({
        batchDeliveryStatus: false,
        batchDeliveryStatusSuc: false,
        batchDeliveryStatusErr: true,
      })
    }

    case cons.SET_REMARK: {
      return state.merge({
        setRemark: true,
        setRemarkSuc: false,
        setRemarkErr: false,
      })
    }
    case cons.SET_REMARK_SUC: {
      return state.merge({
        setRemark: false,
        setRemarkSuc: true,
        setRemarkErr: false,
      })
    }
    case cons.SET_REMARK_ERR: {
      return state.merge({
        setRemark: false,
        setRemarkSuc: false,
        setRemarkErr: true,
      })
    }

    case cons.BATCH_SET_STAY: {
      return state.merge({
        batchSetStay: true,
        batchSetStaySuc: false,
        batchSetStayErr: false,
      })
    }
    case cons.BATCH_SET_STAY_SUC: {
      return state.merge({
        batchSetStay: false,
        batchSetStaySuc: true,
        batchSetStayErr: false,
      })
    }
    case cons.BATCH_SET_STAY_ERR: {
      return state.merge({
        batchSetStay: false,
        batchSetStaySuc: false,
        batchSetStayErr: true,
      })
    }

    case cons.BATCH_FIRST: {
      return state.merge({
        batchSetFirst: true,
        batchSetFirstSuc: false,
        batchSetFirstErr: false,
      })
    }
    case cons.BATCH_FIRST_SUC: {
      return state.merge({
        batchSetFirst: false,
        batchSetFirstSuc: true,
        batchSetFirstErr: false,
      })
    }
    case cons.BATCH_FIRST_ERR: {
      return state.merge({
        batchSetFirst: false,
        batchSetFirstSuc: false,
        batchSetFirstErr: true,
      })
    }

    case cons.CLEAR_ORDER: {
      return state.merge({
        ...INIT_USER,
      })
    }

    default:
      return state;
  }
}
