export default {
  GET_PRODUCT_LIST: 'product/GET_PRODUCT_LIST',
  GET_PRODUCT_LIST_SUC: 'product/GET_PRODUCT_LIST_SUC',
  GET_PRODUCT_LIST_ERR: 'product/GET_PRODUCT_LIST_ERR',

  GET_PRODUCT_STOCK_LIST: 'product/GET_PRODUCT_STOCK_LIST',
  GET_PRODUCT_STOCK_LIST_SUC: 'product/GET_PRODUCT_STOCK_LIST_SUC',
  GET_PRODUCT_STOCK_LIST_ERR: 'product/GET_PRODUCT_STOCK_LIST_ERR',

  GET_PRODUCT_LIST_IDS: 'product/GET_PRODUCT_LIST_IDS',
  GET_PRODUCT_LIST_IDS_SUC: 'product/GET_PRODUCT_LIST_IDS_SUC',
  GET_PRODUCT_LIST_IDS_ERR: 'product/GET_PRODUCT_LIST_IDS_ERR',

  SYNC_PRODUCT: 'product/SYNC_PRODUCT',
  SYNC_PRODUCT_SUC: 'product/SYNC_PRODUCT_SUC',
  SYNC_PRODUCT_ERR: 'product/SYNC_PRODUCT_ERR',

  UPDATE_QTY: 'product/UPDATE_QTY',
  UPDATE_QTY_SUC: 'product/UPDATE_QTY_SUC',
  UPDATE_QTY_ERR: 'product/UPDATE_QTY_ERR',

  GET_PRODUCT_STOSK_LIST: 'product/GET_PRODUCT_STOSK_LIST',
  GET_PRODUCT_STOSK_LIST_SUC: 'product/GET_PRODUCT_STOSK_LIST_SUC',
  GET_PRODUCT_STOSK_LIST_ERR: 'product/GET_PRODUCT_STOSK_LIST_ERR',

  CLEAR_STOCK: 'product/CLEAR_STOCK',
}
