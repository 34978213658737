import { fromJS, Map } from 'immutable';
import { manage as cons } from '../constants';

const INIT_USER = {
  importFile: false,
  importFileSuc: false,
  importFileErr: false,
  errItems: [],
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.IMPORT: {
      return state.merge({
        importFile: true,
        importFileSuc: false,
        importFileErr: false,
        errItems: [],
      });
    }
    case cons.IMPORT_SUC: {
      const { errItems } = action.result.data;

      return state.merge({
        importFile: false,
        importFileSuc: true,
        importFileErr: false,
        errItems,
      });
    }
    case cons.IMPORT_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        importFile: false,
        importFileSuc: false,
        importFileErr: errMsg,
      });
    }

    default:
      return state;
  }
}
