import { fromJS, Map } from 'immutable';
import { exchanges as cons } from '../constants';

const INIT_USER = {
  loadExchangeList: false,
  loadExchangeListSuc: false,
  loadExchangeListErr: false,
  dataList: [],
  total: 0,

  addExchange: false,
  addExchangeSuc: false,
  addExchangeErr: false,

  checkExchange: false,
  checkExchangeSuc: false,
  checkExchangeErr: false,

  loadExchange: false,
  loadExchangeSuc: false,
  loadExchangeErr: false,

  updateExchange: false,
  updateExchangeSuc: false,
  updateExchangeErr: false,
  errorList: [],

  deleteExchange: false,
  deleteExchangeSuc: false,
  deleteExchangeErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.GET_LIST: {
      return state.merge({
        loadExchangeList: true,
        loadExchangeListSuc: false,
        loadExchangeListErr: false,
      })
    }
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadExchangeList: false,
        loadExchangeListSuc: true,
        loadExchangeListErr: false,
        dataList: data,
        total,
      })
    }
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadExchangeList: false,
        loadExchangeListSuc: false,
        loadExchangeListErr: true,

      })
    }

    case cons.CREATE: {
      return state.merge({
        addExchange: true,
        addExchangeSuc: false,
        addExchangeErr: false,
      });
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addExchange: false,
        addExchangeSuc: true,
        addExchangeErr: false,
      });
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addExchange: false,
        addExchangeSuc: false,
        addExchangeErr: errMsg,
      });
    }

    case cons.CHECK: {
      return state.merge({
        checkExchange: true,
        checkExchangeSuc: false,
        checkExchangeErr: false,
      });
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkExchange: false,
        checkExchangeSuc: true,
        checkExchangeErr: false,
      });
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkExchange: false,
        checkExchangeSuc: false,
        checkExchangeErr: errMsg,
      });
    }

    case cons.GET_EXCHANGE: {
      return state.merge({
        loadExchange: true,
        loadExchangeSuc: false,
        loadExchangeErr: false,
      });
    }
    case cons.GET_EXCHANGE_SUC: {
      return state.merge({
        loadExchange: false,
        loadExchangeSuc: true,
        loadExchangeErr: false,
      });
    }
    case cons.GET_EXCHANGE_ERR: {
      return state.merge({
        loadExchange: false,
        loadExchangeSuc: false,
        loadExchangeErr: true,
      });
    }

    case cons.UPDATE: {
      return state.merge({
        updateExchange: true,
        updateExchangeSuc: false,
        updateExchangeErr: false,
      });
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateExchange: false,
        updateExchangeSuc: true,
        updateExchangeErr: false,
      });
    }
    case cons.UPDATE_ERR: {
      const { message, errorList } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateExchange: false,
        updateExchangeSuc: false,
        updateExchangeErr: errMsg,
        errorList: errorList,
      });
    }

    case cons.DELETE: {
      return state.merge({
        deleteExchange: true,
        deleteExchangeSuc: false,
        deleteExchangeErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteExchange: false,
        deleteExchangeSuc: true,
        deleteExchangeErr: false,
      });
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deleteExchange: false,
        deleteExchangeSuc: false,
        deleteExchangeErr: errMsg,
      });
    }

    default:
      return state;
  }
}
