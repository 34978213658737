import { FlashOnRounded } from '@material-ui/icons';
import { fromJS, Map } from 'immutable';
import { transfers as cons } from '../constants';

const INIT_USER = {
  loadTransferList: false,
  loadTransferListSuc: false,
  loadTransferListErr: false,
  dataList: [],
  total: 0,

  loadTransferLogs: false,
  loadTransferLogsSuc: false,
  loadTransferLogsErr: false,
  logList: [],
  logTotal: 0,

  importFile: false,
  importFileSuc: false,
  importFileErr: false,

  addTransfer: false,
  addTransferSuc: false,
  addTransferErr: false,

  checkTransfer: false,
  checkTransferSuc: false,
  checkTransferErr: false,

  loadTransfer: false,
  loadTransferSuc: false,
  loadTransferErr: false,

  updateTransfer: false,
  updateTransferSuc: false,
  updateTransferErr: false,

  deleteTransfer: false,
  deleteTransferSuc: false,
  deleteTransferErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.GET_LIST: {
      return state.merge({
        loadTransferList: true,
        loadTransferListSuc: false,
        loadTransferListErr: false,
      })
    }
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadTransferList: false,
        loadTransferListSuc: true,
        loadTransferListErr: false,
        dataList: data,
        total,
      })
    }
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadTransferList: false,
        loadTransferListSuc: false,
        loadTransferListErr: true,

      })
    }

    case cons.GET_LOG_LIST: {
      return state.merge({
        loadTransferLogs: true,
        loadTransferLogsSuc: false,
        loadTransferLogsErr: false,
      })
    }
    case cons.GET_LOG_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadTransferLogs: false,
        loadTransferLogsSuc: true,
        loadTransferLogsErr: false,
        logList: data,
        logTotal: total,
      })
    }
    case cons.GET_LOG_LIST_ERR: {
      return state.merge({
        loadTransferLogs: false,
        loadTransferLogsSuc: false,
        loadTransferLogsErr: true,
      })
    }

    case cons.IMPORT: {
      return state.merge({
        importFile: true,
        importFileSuc: false,
        importFileErr: false,
      });
    }
    case cons.IMPORT_SUC: {
      return state.merge({
        importFile: false,
        importFileSuc: true,
        importFileErr: false,
      });
    }
    case cons.IMPORT_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        importFile: false,
        importFileSuc: false,
        importFileErr: errMsg,
      });
    }

    case cons.CREATE: {
      return state.merge({
        addTransfer: true,
        addTransferSuc: false,
        addTransferErr: false,
      });
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addTransfer: false,
        addTransferSuc: true,
        addTransferErr: false,
      });
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addTransfer: false,
        addTransferSuc: false,
        addTransferErr: errMsg,
      });
    }

    case cons.CHECK: {
      return state.merge({
        checkTransfer: true,
        checkTransferSuc: false,
        checkTransferErr: false,
      });
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkTransfer: false,
        checkTransferSuc: true,
        checkTransferErr: false,
      });
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkTransfer: false,
        checkTransferSuc: false,
        checkTransferErr: errMsg,
      });
    }

    case cons.GET_TRANSFER: {
      return state.merge({
        loadTransfer: true,
        loadTransferSuc: false,
        loadTransferErr: false,
      });
    }
    case cons.GET_TRANSFER_SUC: {
      return state.merge({
        loadTransfer: false,
        loadTransferSuc: true,
        loadTransferErr: false,
      });
    }
    case cons.GET_TRANSFER_ERR: {
      return state.merge({
        loadTransfer: false,
        loadTransferSuc: false,
        loadTransferErr: true,
      });
    }

    case cons.UPDATE: {
      return state.merge({
        updateTransfer: true,
        updateTransferSuc: false,
        updateTransferErr: false,
      });
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateTransfer: false,
        updateTransferSuc: true,
        updateTransferErr: false,
      });
    }
    case cons.UPDATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateTransfer: false,
        updateTransferSuc: false,
        updateTransferErr: errMsg,
      });
    }

    case cons.DELETE: {
      return state.merge({
        deleteTransfer: true,
        deleteTransferSuc: false,
        deleteTransferErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteTransfer: false,
        deleteTransferSuc: true,
        deleteTransferErr: false,
      });
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deleteTransfer: false,
        deleteTransferSuc: false,
        deleteTransferErr: errMsg,
      });
    }

    default:
      return state;
  }
}
