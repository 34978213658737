export default {
  SIGN_IN: 'auth/SIGN_IN',
  SIGN_IN_SUC: 'auth/SIGN_IN_SUC',
  SIGN_IN_ERR: 'auth/SIGN_IN_ERR',

  GET_ME: 'auth/GET_ME',
  GET_ME_SUC: 'auth/GET_ME_SUC',
  GET_ME_ERR: 'auth/GET_ME_ERR',

  UPDATE_ROUTE: 'auth/UPDATE_ROUTE',
  SIGN_OUT: 'auth/SIGN_OUT',
}
