import { fromJS, Map } from 'immutable';
import { purchases as cons } from '../constants';

const INIT_USER = {
  loadPurchaseList: false,
  loadPurchaseListSuc: false,
  loadPurchaseListErr: false,
  dataList: [],
  total: 0,

  importFile: false,
  importFileSuc: false,
  importFileErr: false,

  addPurchase: false,
  addPurchaseSuc: false,
  addPurchaseErr: false,

  checkPurchase: false,
  checkPurchaseSuc: false,
  checkPurchaseErr: false,

  loadPurchase: false,
  loadPurchaseSuc: false,
  loadPurchaseErr: false,

  updatePurchase: false,
  updatePurchaseSuc: false,
  updatePurchaseErr: false,

  deletePurchase: false,
  deletePurchaseSuc: false,
  deletePurchaseErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.GET_LIST: {
      return state.merge({
        loadPurchaseList: true,
        loadPurchaseListSuc: false,
        loadPurchaseListErr: false,
      })
    }
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadPurchaseList: false,
        loadPurchaseListSuc: true,
        loadPurchaseListErr: false,
        dataList: data,
        total,
      })
    }
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadPurchaseList: false,
        loadPurchaseListSuc: false,
        loadPurchaseListErr: true,

      })
    }

    case cons.IMPORT: {
      return state.merge({
        importFile: true,
        importFileSuc: false,
        importFileErr: false,
      });
    }
    case cons.IMPORT_SUC: {
      return state.merge({
        importFile: false,
        importFileSuc: true,
        importFileErr: false,
      });
    }
    case cons.IMPORT_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        importFile: false,
        importFileSuc: false,
        importFileErr: errMsg,
      });
    }

    case cons.CREATE: {
      return state.merge({
        addPurchase: true,
        addPurchaseSuc: false,
        addPurchaseErr: false,
      });
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addPurchase: false,
        addPurchaseSuc: true,
        addPurchaseErr: false,
      });
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addPurchase: false,
        addPurchaseSuc: false,
        addPurchaseErr: errMsg,
      });
    }

    case cons.CHECK: {
      return state.merge({
        checkPurchase: true,
        checkPurchaseSuc: false,
        checkPurchaseErr: false,
      });
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkPurchase: false,
        checkPurchaseSuc: true,
        checkPurchaseErr: false,
      });
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkPurchase: false,
        checkPurchaseSuc: false,
        checkPurchaseErr: errMsg,
      });
    }

    case cons.GET_PURCHASE: {
      return state.merge({
        loadPurchase: true,
        loadPurchaseSuc: false,
        loadPurchaseErr: false,
      });
    }
    case cons.GET_PURCHASE_SUC: {
      return state.merge({
        loadPurchase: false,
        loadPurchaseSuc: true,
        loadPurchaseErr: false,
      });
    }
    case cons.GET_PURCHASE_ERR: {
      return state.merge({
        loadPurchase: false,
        loadPurchaseSuc: false,
        loadPurchaseErr: true,
      });
    }

    case cons.UPDATE: {
      return state.merge({
        updatePurchase: true,
        updatePurchaseSuc: false,
        updatePurchaseErr: false,
      });
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updatePurchase: false,
        updatePurchaseSuc: true,
        updatePurchaseErr: false,
      });
    }
    case cons.UPDATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updatePurchase: false,
        updatePurchaseSuc: false,
        updatePurchaseErr: errMsg,
      });
    }

    case cons.DELETE: {
      return state.merge({
        deletePurchase: true,
        deletePurchaseSuc: false,
        deletePurchaseErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deletePurchase: false,
        deletePurchaseSuc: true,
        deletePurchaseErr: false,
      });
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deletePurchase: false,
        deletePurchaseSuc: false,
        deletePurchaseErr: errMsg,
      });
    }

    default:
      return state;
  }
}
