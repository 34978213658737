import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Menu from './menu';
import {storage} from '../../util/config';

// action
import { getMe, signout } from '../../store/action/auth';

import './index.scss';

class App extends Component {
  static propTypes = {
    loginChecker: PropTypes.bool.isRequired,
    setChechLogin: PropTypes.func.isRequired,
    auth: ImmutablePropTypes.map.isRequired,
    signout: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  constructor(props, context) {
    super(props, context);

    this.handleSignout = this.handleSignout.bind(this);
  }

  componentDidMount() {
    this.props.getMe();
  }

  componentDidUpdate(prevProps) {
    const { auth: prevAuth } = prevProps;
    const { auth } = this.props;

    const prevRouteToggle = prevAuth.get('routeToggle');
    const RouteToggle = auth.get('routeToggle');
    const checkToken =  !!localStorage.getItem(storage.localStorage.token);

    if (prevRouteToggle !== RouteToggle) {
      this.props.setChechLogin(checkToken);

      if (checkToken) {
        this.props.getMe();
      }
    }
  }

  handleSignout() {
    this.props.signout();
    
    setTimeout(() => {
      this.props.setChechLogin(false);
    }, 100);
  }

  render() {
    const {
      auth,
      loginChecker,
      children,
      location: { pathname },
    } = this.props;
    const meObj = auth.get('configObj');
        
    return (
      <div id="app" className="app">
        {loginChecker &&
          <Menu
            meObj={meObj}
            signout={this.handleSignout}
            pathname={pathname}
            history={this.props.history}
          >
            {children}
          </Menu>
        }

        {!loginChecker &&
          <div>
            {children}
          </div>
        }
      </div>
    );
  }
}

const connectApp = connect(
  state => ({
    auth: state.auth,
  }), {
    signout,
    getMe,
  }
)(App);

export default withRouter(connectApp);
