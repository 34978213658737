export default {
  GET_ORDER_LIST: 'orders/GET_ORDER_LIST',
  GET_ORDER_LIST_SUC: 'orders/GET_ORDER_LIST_SUC',
  GET_ORDER_LIST_ERR: 'orders/GET_ORDER_LIST_ERR',

  GET_ORDER_CHECK_LIST: 'orders/GET_ORDER_CHECK_LIST',
  GET_ORDER_CHECK_LIST_SUC: 'orders/GET_ORDER_CHECK_LIST_SUC',
  GET_ORDER_CHECK_LIST_ERR: 'orders/GET_ORDER_CHECK_LIST_ERR',

  GET_SHIPMENT_LIST: 'orders/GET_SHIPMENT_LIST',
  GET_SHIPMENT_LIST_SUC: 'orders/GET_SHIPMENT_LIST_SUC',
  GET_SHIPMENT_LIST_ERR: 'orders/GET_SHIPMENT_LIST_ERR',

  GET_ORDER_STAY_LIST: 'orders/GET_ORDER_STAY_LIST',
  GET_ORDER_STAY_LIST_SUC: 'orders/GET_ORDER_STAY_LIST_SUC',
  GET_ORDER_STAY_LIST_ERR: 'orders/GET_ORDER_STAY_LIST_ERR',

  GET_ORDER_CANCEL_LIST: 'orders/GET_ORDER_CANCEL_LIST',
  GET_ORDER_CANCEL_LIST_SUC: 'orders/GET_ORDER_CANCEL_LIST_SUC',
  GET_ORDER_CANCEL_LIST_ERR: 'orders/GET_ORDER_CANCEL_LIST_ERR',

  GET_ORDER_SHOP_LIST: 'orders/GET_ORDER_SHOP_LIST',
  GET_ORDER_SHOP_LIST_SUC: 'orders/GET_ORDER_SHOP_LIST_SUC',
  GET_ORDER_SHOP_LIST_ERR: 'orders/GET_ORDER_SHOP_LIST_ERR',

  ORDER_CHECK: 'orders/ORDER_CHECK',
  ORDER_CHECK_SUC: 'orders/ORDER_CHECK_SUC',
  ORDER_CHECK_ERR: 'orders/ORDER_CHECK_ERR',

  SYNC_ORDER: 'orders/SYNC_ORDER',
  SYNC_ORDER_SUC: 'orders/SYNC_ORDER_SUC',
  SYNC_ORDER_ERR: 'orders/SYNC_ORDER_ERR',

  UPDATE_ORDER: 'orders/UPDATE_ORDER',
  UPDATE_ORDER_SUC: 'orders/UPDATE_ORDER_SUC',
  UPDATE_ORDER_ERR: 'orders/UPDATE_ORDER_ERR',

  CHECK_ORDER: 'orders/CHECK_ORDER',
  CHECK_ORDER_SUC: 'orders/CHECK_ORDER_SUC',
  CHECK_ORDER_ERR: 'orders/CHECK_ORDER_ERR',

  LACK_ORDER: 'orders/LACK_ORDER',
  LACK_ORDER_SUC: 'orders/LACK_ORDER_SUC',
  LACK_ORDER_ERR: 'orders/LACK_ORDER_ERR',

  MERGE_ORDER: 'orders/MERGE_ORDER',
  MERGE_ORDER_SUC: 'orders/MERGE_ORDER_SUC',
  MERGE_ORDER_ERR: 'orders/MERGE_ORDER_ERR',

  GENERATE_LABEL: 'orders/GENERATE_LABEL',
  GENERATE_LABEL_SUC: 'orders/GENERATE_LABEL_SUC',
  GENERATE_LABEL_ERR: 'orders/GENERATE_LABEL_ERR',

  BATCH_STATUS: 'orders/BATCH_STATUS',
  BATCH_STATUS_SUC: 'orders/BATCH_STATUS_SUC',
  BATCH_STATUS_ERR: 'orders/BATCH_STATUS_ERR',

  BATCH_DELIVERY_STATUS: 'orders/BATCH_DELIVERY_STATUS',
  BATCH_DELIVERY_STATUS_SUC: 'orders/BATCH_DELIVERY_STATUS_SUC',
  BATCH_DELIVERY_STATUS_ERR: 'orders/BATCH_DELIVERY_STATUS_ERR',

  BATCH_FIRST: 'orders/BATCH_FIRST',
  BATCH_FIRST_SUC: 'orders/BATCH_FIRST_SUC',
  BATCH_FIRST_ERR: 'orders/BATCH_FIRST_ERR',

  SET_REMARK: 'order/SET_REMARK',
  SET_REMARK_SUC: 'order/SET_REMARK_SUC',
  SET_REMARK_ERR: 'order/SET_REMARK_ERR',

  BATCH_SET_STAY: 'order/BATCH_SET_STAY',
  BATCH_SET_STAY_SUC: 'order/BATCH_SET_STAY_SUC',
  BATCH_SET_STAY_ERR: 'order/BATCH_SET_STAY_ERR',

  CLEAR_ORDER: 'order/CLEAR_ORDER',
}
