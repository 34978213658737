import { fromJS, Map } from 'immutable';
import { receipts as cons } from '../constants';

const INIT_USER = {
  loadReceiptList: false,
  loadReceiptListSuc: false,
  loadReceiptListErr: false,
  dataList: [],
  total: 0,

  importFile: false,
  importFileSuc: false,
  importFileErr: false,

  addReceipt: false,
  addReceiptSuc: false,
  addReceiptErr: false,

  checkReceipt: false,
  checkReceiptSuc: false,
  checkReceiptErr: false,

  loadReceipt: false,
  loadReceiptSuc: false,
  loadReceiptErr: false,

  updateReceipt: false,
  updateReceiptSuc: false,
  updateReceiptErr: false,

  deleteReceipt: false,
  deleteReceiptSuc: false,
  deleteReceiptErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.GET_LIST: {
      return state.merge({
        loadReceiptList: true,
        loadReceiptListSuc: false,
        loadReceiptListErr: false,
      })
    }
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadReceiptList: false,
        loadReceiptListSuc: true,
        loadReceiptListErr: false,
        dataList: data,
        total,
      })
    }
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadReceiptList: false,
        loadReceiptListSuc: false,
        loadReceiptListErr: true,

      })
    }

    case cons.IMPORT: {
      return state.merge({
        importFile: true,
        importFileSuc: false,
        importFileErr: false,
      });
    }
    case cons.IMPORT_SUC: {
      return state.merge({
        importFile: false,
        importFileSuc: true,
        importFileErr: false,
      });
    }
    case cons.IMPORT_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        importFile: false,
        importFileSuc: false,
        importFileErr: errMsg,
      });
    }

    case cons.CREATE: {
      return state.merge({
        addReceipt: true,
        addReceiptSuc: false,
        addReceiptErr: false,
      });
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addReceipt: false,
        addReceiptSuc: true,
        addReceiptErr: false,
      });
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addReceipt: false,
        addReceiptSuc: false,
        addReceiptErr: errMsg,
      });
    }

    case cons.CHECK: {
      return state.merge({
        checkReceipt: true,
        checkReceiptSuc: false,
        checkReceiptErr: false,
      });
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkReceipt: false,
        checkReceiptSuc: true,
        checkReceiptErr: false,
      });
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkReceipt: false,
        checkReceiptSuc: false,
        checkReceiptErr: errMsg,
      });
    }

    case cons.GET_RECEIPT: {
      return state.merge({
        loadReceipt: true,
        loadReceiptSuc: false,
        loadReceiptErr: false,
      });
    }
    case cons.GET_RECEIPT_SUC: {
      return state.merge({
        loadReceipt: false,
        loadReceiptSuc: true,
        loadReceiptErr: false,
      });
    }
    case cons.GET_RECEIPT_ERR: {
      return state.merge({
        loadReceipt: false,
        loadReceiptSuc: false,
        loadReceiptErr: true,
      });
    }

    case cons.UPDATE: {
      return state.merge({
        updateReceipt: true,
        updateReceiptSuc: false,
        updateReceiptErr: false,
      });
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateReceipt: false,
        updateReceiptSuc: true,
        updateReceiptErr: false,
      });
    }
    case cons.UPDATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateReceipt: false,
        updateReceiptSuc: false,
        updateReceiptErr: errMsg,
      });
    }

    case cons.DELETE: {
      return state.merge({
        deleteReceipt: true,
        deleteReceiptSuc: false,
        deleteReceiptErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteReceipt: false,
        deleteReceiptSuc: true,
        deleteReceiptErr: false,
      });
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deleteReceipt: false,
        deleteReceiptSuc: false,
        deleteReceiptErr: errMsg,
      });
    }

    default:
      return state;
  }
}
