import { fromJS, Map } from 'immutable';
import { delivery as cons } from '../constants';

const INIT_USER = {
  loadList: false,
  loadListSuc: false,
  loadListErr: false,
  dataList: [],
  total: 0,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type) {
    case cons.GET_DELIVERY_LIST: {
      return state.merge({
        loadList: true,
        loadListSuc: false,
        loadListErr: false,
      });
    }
    case cons.GET_DELIVERY_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadList: false,
        loadListSuc: true,
        loadListErr: false,
        dataList: data,
        total,
      });
    }
    case cons.GET_DELIVERY_LIST_ERR: {
      return state.merge({
        loadList: false,
        loadListSuc: false,
        loadListErr: true,
      });
    }

    default:
      return state;
  }
}
