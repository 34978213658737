import { fromJS, Map } from 'immutable';
import { returns as cons } from '../constants';

const INIT_USER = {
  loadReturnList: false,
  loadReturnListSuc: false,
  loadReturnListErr: false,
  dataList: [],
  total: 0,

  importFile: false,
  importFileSuc: false,
  importFileErr: false,

  addReturn: false,
  addReturnSuc: false,
  addReturnErr: false,

  checkReturn: false,
  checkReturnSuc: false,
  checkReturnErr: false,

  loadReturn: false,
  loadReturnSuc: false,
  loadReturnErr: false,

  updateReturn: false,
  updateReturnSuc: false,
  updateReturnErr: false,

  deleteReturn: false,
  deleteReturnSuc: false,
  deleteReturnErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  switch (action.type){
    case cons.GET_LIST: {
      return state.merge({
        loadReturnList: true,
        loadReturnListSuc: false,
        loadReturnListErr: false,
      })
    }
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadReturnList: false,
        loadReturnListSuc: true,
        loadReturnListErr: false,
        dataList: data,
        total,
      })
    }
    case cons.GET_LIST_ERR: {
      return state.merge({
        loadReturnList: false,
        loadReturnListSuc: false,
        loadReturnListErr: true,

      })
    }

    case cons.IMPORT: {
      return state.merge({
        importFile: true,
        importFileSuc: false,
        importFileErr: false,
      });
    }
    case cons.IMPORT_SUC: {
      return state.merge({
        importFile: false,
        importFileSuc: true,
        importFileErr: false,
      });
    }
    case cons.IMPORT_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        importFile: false,
        importFileSuc: false,
        importFileErr: errMsg,
      });
    }

    case cons.CREATE: {
      return state.merge({
        addReturn: true,
        addReturnSuc: false,
        addReturnErr: false,
      });
    }
    case cons.CREATE_SUC: {
      return state.merge({
        addReturn: false,
        addReturnSuc: true,
        addReturnErr: false,
      });
    }
    case cons.CREATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addReturn: false,
        addReturnSuc: false,
        addReturnErr: errMsg,
      });
    }

    case cons.CHECK: {
      return state.merge({
        checkReturn: true,
        checkReturnSuc: false,
        checkReturnErr: false,
      });
    }
    case cons.CHECK_SUC: {
      return state.merge({
        checkReturn: false,
        checkReturnSuc: true,
        checkReturnErr: false,
      });
    }
    case cons.CHECK_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        checkReturn: false,
        checkReturnSuc: false,
        checkReturnErr: errMsg,
      });
    }

    case cons.GET_RETURN: {
      return state.merge({
        loadReturn: true,
        loadReturnSuc: false,
        loadReturnErr: false,
      });
    }
    case cons.GET_RETURN_SUC: {
      return state.merge({
        loadReturn: false,
        loadReturnSuc: true,
        loadReturnErr: false,
      });
    }
    case cons.GET_RETURN_ERR: {
      return state.merge({
        loadReturn: false,
        loadReturnSuc: false,
        loadReturnErr: true,
      });
    }

    case cons.UPDATE: {
      return state.merge({
        updateReturn: true,
        updateReturnSuc: false,
        updateReturnErr: false,
      });
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateReturn: false,
        updateReturnSuc: true,
        updateReturnErr: false,
      });
    }
    case cons.UPDATE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateReturn: false,
        updateReturnSuc: false,
        updateReturnErr: errMsg,
      });
    }

    case cons.DELETE: {
      return state.merge({
        deleteReturn: true,
        deleteReturnSuc: false,
        deleteReturnErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteReturn: false,
        deleteReturnSuc: true,
        deleteReturnErr: false,
      });
    }
    case cons.DELETE_ERR: {
      const { message } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        deleteReturn: false,
        deleteReturnSuc: false,
        deleteReturnErr: errMsg,
      });
    }

    default:
      return state;
  }
}
