import { fromJS, Map } from 'immutable';
import { accounts as cons } from '../constants';

const INIT_USER = {
  loadList: false,
  loadListSuc: false,
  loadListErr: false,
  dataList: [],
  total: 0,

  addAccount: false,
  addAccountSuc: false,
  addAccountErr: false,

  loadAccount: false,
  loadAccountSuc: false,
  loadAccountErr: false,
  dataObj: {},

  updateAccount: false,
  updateAccountSuc: false,
  updateAccountErr: false,

  deleteAccount: false,
  deleteAccountSuc: false,
  deleteAccountErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  
  switch (action.type) {
    case cons.GET_LIST:
      return state.merge({
        loadList: true,
        loadListSuc: false,
        loadListErr: false,
      });
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadList: false,
        loadListSuc: true,
        loadListErr: false,
        dataList: data,
        total,
      });
    }
    case cons.GET_LIST_ERR:
      return state.merge({
        loadList: false,
        loadListSuc: false,
        loadListErr: true,
      });

    case cons.CREATE:
      return state.merge({
        addAccount: true,
        addAccountSuc: false,
        addAccountErr: false,
      });
    case cons.CREATE_SUC:
      return state.merge({
        addAccount: false,
        addAccountSuc: true,
        addAccountErr: false,
      });
    case cons.CREATE_ERR: {
      const { message, success } = action.result.data;

      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addAccount: false,
        addAccountSuc: false,
        addAccountErr: errMsg,
      });
    }

    case cons.GET_ACCOUNT: {
      return state.merge({
        loadAccount: true,
        loadAccountSuc: false,
        loadAccountErr: false,
      })
    }
    case cons.GET_ACCOUNT_SUC: {
      const { data } = action.result.data;

      return state.merge({
        loadAccount: false,
        loadAccountSuc: true,
        loadAccountErr: false,
        dataObj: data,
      })
    }
    case cons.GET_ACCOUNT_ERR: {
      return state.merge({
        loadAccount: false,
        loadAccountSuc: false,
        loadAccountErr: true,
      })
    }

    case cons.UPDATE: {
      return state.merge({
        updateAccount: true,
        updateAccountSuc: false,
        updateAccountErr: false,
      })
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateAccount: false,
        updateAccountSuc: true,
        updateAccountErr: false,
      })
    }
    case cons.UPDATE_ERR: {
      return state.merge({
        updateAccount: false,
        updateAccountSuc: false,
        updateAccountErr: true,
      })
    }

    case cons.DELETE: {
      return state.merge({
        deleteAccount: true,
        deleteAccountSuc: false,
        deleteAccountErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteAccount: false,
        deleteAccountSuc: true,
        deleteAccountErr: false,
      });
    }
    case cons.DELETE_ERR: {
      return state.merge({
        deleteAccount: false,
        deleteAccountSuc: false,
        deleteAccountErr: true,
      });
    }
      

    default:
      return state;
  }
}
