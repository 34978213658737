import { fromJS, Map } from 'immutable';
import { warehouses as cons } from '../constants';

const INIT_USER = {
  loadList: false,
  loadListSuc: false,
  loadListErr: false,
  dataList: [],
  total: 0,

  addWarehouse: false,
  addWarehouseSuc: false,
  addWarehouseErr: false,

  loadWarehouse: false,
  loadWarehouseSuc: false,
  loadWarehouseErr: false,
  dataObj: {},

  updateWarehouse: false,
  updateWarehouseSuc: false,
  updateWarehouseErr: false,

  deleteWarehouse: false,
  deleteWarehouseSuc: false,
  deleteWarehouseErr: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  
  switch (action.type) {
    case cons.GET_LIST:
      return state.merge({
        loadList: true,
        loadListSuc: false,
        loadListErr: false,
      });
    case cons.GET_LIST_SUC: {
      const { data, total } = action.result.data;

      return state.merge({
        loadList: false,
        loadListSuc: true,
        loadListErr: false,
        dataList: data,
        total,
      });
    }
    case cons.GET_LIST_ERR:
      return state.merge({
        loadList: false,
        loadListSuc: false,
        loadListErr: true,
      });

    case cons.CREATE:
      return state.merge({
        addWarehouse: true,
        addWarehouseSuc: false,
        addWarehouseErr: false,
      });
    case cons.CREATE_SUC:
      return state.merge({
        addWarehouse: false,
        addWarehouseSuc: true,
        addWarehouseErr: false,
      });
    case cons.CREATE_ERR: {
      const { message, success } = action.result.data;

      let errMsg = message || '伺服器錯誤';

      return state.merge({
        addWarehouse: false,
        addWarehouseSuc: false,
        addWarehouseErr: errMsg,
      });
    }

    case cons.GET_WAREHOUSE: {
      return state.merge({
        loadWarehouse: true,
        loadWarehouseSuc: false,
        loadWarehouseErr: false,
      })
    }
    case cons.GET_WAREHOUSE_SUC: {
      const { data } = action.result.data;

      return state.merge({
        loadWarehouse: false,
        loadWarehouseSuc: true,
        loadWarehouseErr: false,
        dataObj: data,
      })
    }
    case cons.GET_WAREHOUSE_ERR: {
      return state.merge({
        loadWarehouse: false,
        loadWarehouseSuc: false,
        loadWarehouseErr: true,
      })
    }

    case cons.UPDATE: {
      return state.merge({
        updateWarehouse: true,
        updateWarehouseSuc: false,
        updateWarehouseErr: false,
      })
    }
    case cons.UPDATE_SUC: {
      return state.merge({
        updateWarehouse: false,
        updateWarehouseSuc: true,
        updateWarehouseErr: false,
      })
    }
    case cons.UPDATE_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';

      return state.merge({
        updateWarehouse: false,
        updateWarehouseSuc: false,
        updateWarehouseErr: errMsg,
      })
    }

    case cons.DELETE: {
      return state.merge({
        deleteWarehouse: true,
        deleteWarehouseSuc: false,
        deleteWarehouseErr: false,
      });
    }
    case cons.DELETE_SUC: {
      return state.merge({
        deleteWarehouse: false,
        deleteWarehouseSuc: true,
        deleteWarehouseErr: false,
      });
    }
    case cons.DELETE_ERR: {
      return state.merge({
        deleteWarehouse: false,
        deleteWarehouseSuc: false,
        deleteWarehouseErr: true,
      });
    }

    default:
      return state;
  }
}
