export default {
  GET_LIST: 'exchange/GET_LIST',
  GET_LIST_SUC: 'exchange/GET_LIST_SUC',
  GET_LIST_ERR: 'exchange/GET_LIST_ERR',

  CREATE: 'exchange/CREATE',
  CREATE_SUC: 'exchange/CREATE_SUC',
  CREATE_ERR: 'exchange/CREATE_ERR',

  CHECK: 'exchange/CHECK',
  CHECK_SUC: 'exchange/CHECK_SUC',
  CHECK_ERR: 'exchange/CHECK_ERR',

  GET_EXCHANGE: 'exchange/GET_EXCHANGE',
  GET_EXCHANGE_SUC: 'exchange/GET_EXCHANGE_SUC',
  GET_EXCHANGE_ERR: 'exchange/GET_EXCHANGE_ERR',

  UPDATE: 'exchange/UPDATE',
  UPDATE_SUC: 'exchange/UPDATE_SUC',
  UPDATE_ERR: 'exchange/UPDATE_ERR',

  DELETE: 'exchange/DELETE',
  DELETE_SUC: 'exchange/DELETE_SUC',
  DELETE_ERR: 'exchange/DELETE_ERR',
}
