import {storage} from './config';

class Util {
  isLogin = () => {
    const token = localStorage.getItem(storage.localStorage.token);
    return !!token;
  }
}

export default new Util();
