const API_PATH = {
  init: '//localhost:9168/api', // 内网API地址
  local: '//localhost:3030', // mock api
  prod: '/api', //(暫定) 外网API地址
};
const PREXY_ACCOUNT_TOKEN = 'system_token';
const environment = process.env.REACT_APP_SERVER || 'init';
const API = API_PATH[environment];

const config = {
  API,
  PREXY_ACCOUNT_TOKEN
};

// storage name
export const storage = {
  localStorage: {
    token: 'system_token', // token
    user: 'user', // login user info
    menus: 'menus', // menus
    async: 'async', // async
    errorTimeout: 'errorTimeout', // login error time out
  },
  sessionStorage: {
    menus: 'menus', // menus state
    tips: 'tips' // tips info
  }
};

export default config
