export default function clientMiddleware(apiClient) {
  return ({dispatch, getState}) => {
    return next => action => {
      
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const {promises, types, ...rest} = action;
      let finalPromise = null;

      if (!promises) {
        return next(action);
      }

      finalPromise = promises(apiClient);

      const [REQUEST, SUCCESS, FAILURE] = types;

      if (REQUEST) {
        next({...rest, type: REQUEST});
      }

      return finalPromise.then((result) => {
        if (result.status === 200 || result.status === 304 || result.status === 201) {
          next({...rest, result, type: SUCCESS});
            
        } else {
          
          next({...rest, result, type: FAILURE})
        }

      }).catch((error) => {
        const { response } = error;
        
        if ( response && response.status === 403) {
          next({...rest, error, type: 'auth/SIGN_OUT'});

        } else {
          next({...rest, result: response, type: FAILURE});
          // next({...rest, error, type: FAILURE});
        }

      }).finally(() => {

        if (apiClient.defaults.headers['Content-Type']) {
          delete apiClient.defaults.headers['Content-Type'];
        }
      });
    };
  };
}
