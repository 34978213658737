export default {
  GET_LIST: 'purchases/GET_LIST',
  GET_LIST_SUC: 'purchases/GET_LIST_SUC',
  GET_LIST_ERR: 'purchases/GET_LIST_ERR',

  IMPORT: 'purchases/IMPORT',
  IMPORT_SUC: 'purchases/IMPORT_SUC',
  IMPORT_ERR: 'purchases/IMPORT_ERR',

  CREATE: 'purchases/CREATE',
  CREATE_SUC: 'purchases/CREATE_SUC',
  CREATE_ERR: 'purchases/CREATE_ERR',

  CHECK: 'purchases/CHECK',
  CHECK_SUC: 'purchases/CHECK_SUC',
  CHECK_ERR: 'purchases/CHECK_ERR',

  GET_PURCHASE: 'purchases/GET_PURCHASE',
  GET_PURCHASE_SUC: 'purchases/GET_PURCHASE_SUC',
  GET_PURCHASE_ERR: 'purchases/GET_PURCHASE_ERR',

  UPDATE: 'purchases/UPDATE',
  UPDATE_SUC: 'purchases/UPDATE_SUC',
  UPDATE_ERR: 'purchases/UPDATE_ERR',

  DELETE: 'purchases/DELETE',
  DELETE_SUC: 'purchases/DELETE_SUC',
  DELETE_ERR: 'purchases/DELETE_ERR',
}
