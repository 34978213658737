export default {
  GET_LIST: 'transfer/GET_LIST',
  GET_LIST_SUC: 'transfer/GET_LIST_SUC',
  GET_LIST_ERR: 'transfer/GET_LIST_ERR',

  GET_LOG_LIST: 'transfer/GET_LOG_LIST',
  GET_LOG_LIST_SUC: 'transfer/GET_LOG_LIST_SUC',
  GET_LOG_LIST_ERR: 'transfer/GET_LOG_LIST_ERR',

  IMPORT: 'transfer/IMPORT',
  IMPORT_SUC: 'transfer/IMPORT_SUC',
  IMPORT_ERR: 'transfer/IMPORT_ERR',

  CREATE: 'transfer/CREATE',
  CREATE_SUC: 'transfer/CREATE_SUC',
  CREATE_ERR: 'transfer/CREATE_ERR',

  CHECK: 'transfer/CHECK',
  CHECK_SUC: 'CHECtransfer/K_SUC',
  CHECK_ERR: 'CHECtransfer/K_ERR',

  GET_TRANSFER: 'transfer/GET_TRANSFER',
  GET_TRANSFER_SUC: 'transfer/GET_TRANSFER_SUC',
  GET_TRANSFER_ERR: 'transfer/GET_TRANSFER_ERR',

  UPDATE: 'transfer/UPDATE',
  UPDATE_SUC: 'transfer/UPDATE_SUC',
  UPDATE_ERR: 'transfer/UPDATE_ERR',

  DELETE: 'transfer/DELETE',
  DELETE_SUC: 'transfer/DELETE_SUC',
  DELETE_ERR: 'transfer/DELETE_ERR',
}
