import { auth as cons } from '../constants';

export const signin = (data) => {
  return {
    types: [
      cons.SIGN_IN,
      cons.SIGN_IN_SUC,
      cons.SIGN_IN_ERR,
    ],
    promises: client => client.post('/auth/login', data),
  }
}


export const getMe = () => {
  return {
    types: [
      cons.GET_ME,
      cons.GET_ME_SUC,
      cons.GET_ME_ERR,
    ],
    promises: client => client.get('/auth/me'),
  }
}


export const updateRoute = () => {
  return {
    type: cons.UPDATE_ROUTE,
  }
}

export const signout = () => {
  return {
    type: cons.SIGN_OUT,
  };
}
