
import React from 'react' ;
import {
  AllInbox,
  LocalGroceryStore,
  Assignment,
  Settings,
} from '@material-ui/icons';

export const menuData = [{
  title: '庫存管理',
  path: false,
  icon: (color) => <AllInbox color={color}/>,
  children: [{
    title: '商品管理',
    path: '/material',
  }, {
    title: '倉庫管理',
    path: '/warehouse',
  }, {
    title: '調撥管理',
    path: '/transfer',
  }, {
    title: '盤點/儲位',
    path: '/manage',
  }],
}, {
  title: '採購管理',
  path: false,
  icon: (color) => <LocalGroceryStore color={color}/>,
  children: [{
   title: '採購作業',
    path: '/purchase',
  }, {
    title: '進貨退回',
    path: '/returns',
  }, {
    title: '收料作業',
    path: '/receipt',
  }],
}, {
  title: '訂單管理',
  path: false,
  icon: (color) => <Assignment color={color}/>,
  children: [{
   title: '訂單列表',
    path: '/order',
  }, {
    title: '訂單轉採購',
    path: '/conversion',
  }, {
    title: '退貨列表',
    path: '/return',
  }, {
    title: '換貨列表',
    path: '/exchange',
  }],
}, {
  title: '系統管理',
  path: false,
  icon: (color) => <Settings color={color}/>,
  children: [{
   title: '使用者管理',
    path: '/account',
  }],
}];
