import { fromJS, Map } from 'immutable';
import { auth as cons } from '../constants';
import {storage} from '../../util/config';

const INIT_USER = {
  signIn: false,
  signInSuc: false,
  signInErr: false,

  loadConfig: false,
  loadConfigSuc: false,
  loadConfigErr: false,
  configObj: {},

  routeToggle: false,
}

const initState = fromJS(INIT_USER);

export default function (state = initState, action) {
  
  switch (action.type){
    
    case cons.SIGN_IN:
      return state.merge({  
        signIn: true,
        signInSuc: false,
        signInErr: false,
      });

    case cons.SIGN_IN_SUC: {
      const { token } = action.result.data;
      localStorage.setItem(storage.localStorage.token, token);
      
      return state.merge({  
        signIn: false,
        signInSuc: true,
        signInErr: false,
      });
    }

    case cons.SIGN_IN_ERR: {
      const { message, success } = action.result.data;
      let errMsg = message || '伺服器錯誤';
      
      return state.merge({  
        signIn: false,
        signInSuc: false,
        signInErr: errMsg,
      });
    }

    case cons.GET_ME:
        return state.merge({
          loadConfig: false,
          loadConfigSuc: false,
          loadConfigErr: false,
        });
    case cons.GET_ME_SUC: {
      const { data } = action.result.data;

      return state.merge({
        loadConfig: false,
        loadConfigSuc: false,
        loadConfigErr: false,
        configObj: data,
      });
    }
        
    case cons.GET_ME_ERR:
        return state.merge({
          loadConfig: false,
          loadConfigSuc: false,
          loadConfigErr: false,
        });

    case cons.UPDATE_ROUTE:
      return state.merge({
        routeToggle: !state.get('routeToggle'),
      });

    case cons.SIGN_OUT: {
      localStorage.removeItem(storage.localStorage.token);

      return state.merge({
        ...INIT_USER,
        routeToggle: !state.get('routeToggle'),
      });
    }

    default:
      return state;
  }
}
