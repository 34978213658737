export default {
  GET_LIST: 'receipts/GET_LIST',
  GET_LIST_SUC: 'receipts/GET_LIST_SUC',
  GET_LIST_ERR: 'receipts/GET_LIST_ERR',

  IMPORT: 'receipts/IMPORT',
  IMPORT_SUC: 'receipts/IMPORT_SUC',
  IMPORT_ERR: 'receipts/IMPORT_ERR',

  CREATE: 'receipts/CREATE',
  CREATE_SUC: 'receipts/CREATE_SUC',
  CREATE_ERR: 'receipts/CREATE_ERR',

  CHECK: 'receipts/CHECK',
  CHECK_SUC: 'receipts/CHECK_SUC',
  CHECK_ERR: 'receipts/CHECK_ERR',

  GET_RECEIPT: 'receipts/GET_RECEIPT',
  GET_RECEIPT_SUC: 'receipts/GET_RECEIPT_SUC',
  GET_RECEIPT_ERR: 'receipts/GET_RECEIPT_ERR',

  UPDATE: 'receipts/UPDATE',
  UPDATE_SUC: 'receipts/UPDATE_SUC',
  UPDATE_ERR: 'receipts/UPDATE_ERR',

  DELETE: 'receipts/DELETE',
  DELETE_SUC: 'receipts/DELETE_SUC',
  DELETE_ERR: 'receipts/DELETE_ERR',
}
